.commandbar-wrapper {
  display: none !important;
  display: inline-flex;
  width: calc(100% - 214px);
  height: 4.1rem;
  background: #f3f2f1;
  vertical-align: top;
}

.commandbar-fabric {
  width: 100%;
}

.commandbar-fabric .ms-CommandBar {
  background: #f3f2f1;
  height: 4rem;
  padding-left: 0;
}

.commandbar-fabric .commandbar-button {
  background: #f3f2f1;
  color: #333;
  padding: 0 3.5rem;
}
.commandbar-fabric .commandbar-button:hover {
  background: #eaeaea;
  color: #333;
}

.commandbar-fabric i {
  color: #0089be !important;
}
.commandbar-fabric i:hover {
  color: #0089be !important;
}

.commandbar-fabric .commandbar-button {
  height: 4.1rem;
  padding-top: 0.1rem;
}

@media only screen and (min-width: 768px) {
  .commandbar-wrapper {
    display: inline-flex !important;
  }
}
