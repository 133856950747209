.navigation-header {
    margin-top: 4.5rem;
    margin-bottom: 0.75rem;
    padding-left: 1.25rem;
}

.navigation-header h2 {
    font-family: inherit;
    font-size: 1.4rem;
    font-weight: 500;
    color: #333;
    margin: 0 0.5rem;
}
