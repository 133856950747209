.navbar__navbutton {
  width: 4.8rem;
  height: 4.8rem;
  line-height: 4.8rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2rem;
  vertical-align: top;
  cursor: pointer;
}
.navbar__navbutton:hover {
  background: #005a9e;
}

.navbar__navbutton img {
  width: 2.2rem;
  height: 2.2rem;
  line-height: 5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5rem;
  vertical-align: top;
}
