.navigation-item {
    display: flex;
    align-items: center;
    height: 4.5rem;
    padding: 0.25rem;
}
.navigation-item:hover .navigation-item__icon {
    color: #333;
}

.navigation-item--active {
    background: #d0e3f8;
}

.navigation-item:hover {
    background: #d0e3f8;
    cursor: pointer;
}

.navigation-item__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    width: 4.5rem;
    margin-left: 0.25rem;
    margin-bottom: 0.25rem;
    height: 100%;
    color: #0078d4;
}

.navigation-item__icon i {
    height: 2rem;
}
.navigation-item__title {
    font-size: 1.3rem;
    color: #333333;
    font-family: inherit;
    overflow: hidden;
    max-width: 15rem;
}
