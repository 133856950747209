.navbar-waffle {
  width: 4.8rem;
  height: 4.8rem;
  line-height: 4.8rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  display: none;
}

.navbar-hamburger {
  width: 4.8rem;
  height: 4.8rem;
  line-height: 4.8rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.8rem;
}

.navbar-hamburger:hover {
  background: #005a9e;
  cursor: pointer;
}

.navbar__section-wrapper a {
  height: 4.8rem;
  display: inline-block;
  font-size: 2.2rem;
  text-decoration: none;
  vertical-align: top;
  color: white;
}

a:hover {
  background: #005a9e;
}

.hamburger-menu {
  display: block;
  font-weight: 300;
  background-color: #323130;
  color: #fff;
  font-size: 1.2rem;
}

.hamburger-menu a {
  color: #fff;
  font-size: 1.2rem;
}
.hamburger-menu a:hover {
  text-decoration: none;
}

.hamburger-menu ul {
  padding: 0;
  margin: 0;
}

.hamburger-menu ul li,
.hamburger-menu ul a {
  display: block;
  cursor: pointer;
}

.hamburger-menu ul li .mobile-menu__menu-item-wrapper,
.hamburger-menu ul a .mobile-menu__menu-item-wrapper {
  display: flex;
  width: 100%;
  height: 5rem;
  justify-content: flex-start;
  border-bottom: 0.1rem solid #111;
}

.hamburger-menu ul li .mobile-menu__menu-item-wrapper:hover {
  background: #005a9e;
}

.hamburger-menu ul li .mobile-menu__menu-item-wrapper .mobile-menu__icon,
.hamburger-menu ul a .mobile-menu__menu-item-wrapper .mobile-menu__icon {
  display: flex;
  width: 5rem;
  height: 5rem;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.hamburger-menu ul li .mobile-menu__menu-item-wrapper .mobile-menu__text,
.hamburger-menu ul a .mobile-menu__menu-item-wrapper .mobile-menu__text {
  display: flex;
  width: calc(100% - 30px);
  height: 5rem;
  align-items: center;
  margin-top: 0.1rem;
}

@media only screen and (min-width: 768px) {
  .navbar-waffle {
    display: inline-flex;
  }

  .navbar-hamburger {
    display: none;
  }

  .hamburger-menu {
    display: none;
  }
}
