.navbar {
  width: 100%;
  display: flex;
  height: 4.8rem;
  background: #0078d4;
  position: relative;
}

.navbar__test {
  width: 50%;
  display: flex;
  height: 4.8rem;
  background: royalblue;
  color: #fff;
}

.navbar-area-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 4.8rem;
  line-height: 4.8rem;
}
