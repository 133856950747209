.left-navigation {
  min-height: calc(100vh - 8.9rem);
  display: none;
  flex-direction: column;
  background: #fff;
  border-right: 0.1rem solid #eaeaea;
  width: 21.3rem;
  height: 100%;
}

@media only screen and (min-width: 768px) {
  .left-navigation {
    display: flex;
  }
}
